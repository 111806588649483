import React from 'react';
import styled from 'styled-components'
import { NavLink } from 'react-router-dom';
import Name from "../login/Name"


const Wrapper = styled.div`
    background: #f9f4f3;    
    padding: 5px 0 10px;
    @media (prefers-color-scheme: dark) {
        background: #444;
    }
`
const NavElement = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 860px;
    margin: auto;
    
    a {
        padding: 10 20px; 
        margin: 0 10px;

        display: block;
        text-decoration: none;
       
        &:hover,
        &:focus {
            text-decoration: underline;
            outline: none;
        }
        
        color: blue;
        @media (prefers-color-scheme: dark) {
            color: lightblue;
        }
        &.is-active {
            color: green;
        }
    }
`

function Header() {

    return (
    <Wrapper>
        <NavElement>
            <NavLink to='/'>News</NavLink>
            <NavLink to='/feeds'>Feeds</NavLink>
            <NavLink to='/login'><Name/></NavLink>
        </NavElement>
        
    </Wrapper>
    );

}

export default Header;
