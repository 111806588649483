import axios from "axios";
import {getAuthCode} from "../login/auth"
import {getHost} from "../login/auth"

export default async function fetchFeedItems() {
  try {
    let response = await axios({
        url: getHost() + "/feedItems",
        method: 'get',
        headers: {'Authorization': getAuthCode()}
    })
    return response
  } catch (error) {
    return { error };
  }
}
