import React from 'react';
import styled from 'styled-components'

const ButtonStyled = styled.button`
    margin: auto;  
    display: block;
    width: 100%;
    border: solid 1px rgba(0,0,0,0.2);
    background: rgba(163, 255, 247,0.2);
    box-shadow: inset 0 0 45px 0 rgba(0,0,0,0.05), 1px 1px 3px 1px rgba(0,0,0,0.05);
    transition: box-shadow linear 200ms;
    border-radius: 3px;
    cursor: pointer;

    padding: 12px 20px;
    text-align: left;
    font-size: 16px;  

    @media (prefers-color-scheme: dark) {
        background: rgba(163, 255, 247,0.6);
        color: #fff;
    }

    &:hover,
    &:focus {
        box-shadow: inset 0 0 25px 0 rgba(0,0,0,0.2), 1px 1px 3px 2px rgba(0,0,0,0.2);
        @media (prefers-color-scheme: dark) {
            box-shadow: inset 0 0 15px 0 rgba(0,0,0,0.5), 1px 1px 3px 2px rgba(0,0,0,0.5);
        }
    }  
    
    &.marginTop {
        margin-top: 15px;
    }

    &.-neutral {
        background: transparent;
    }
    &.-delete {
        background: transparent;
        color: rgb(167,0,0);
    }

`

class Button extends React.Component {
    render() {
        let classString = []
        if (this.props.marginTop) {
            classString.push("marginTop")
        }

        if (this.props.layout === "neutral") {
            classString.push("-neutral")
        }

        if (this.props.layout === "delete") {
            classString.push("-delete")
        }

        return (
            <ButtonStyled onClick={this.props.action} className={classString} type={this.props.type}>
                {this.props.children}
            </ButtonStyled>            
      );
    }
}

export default Button