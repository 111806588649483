import React from 'react';
import fetchFeeds from "./fetchFeeds"
import Feed from "./Feed"
import FeedForm from "./FeedAddForm"
import { Link } from 'react-router-dom';
import Box from "../base/Box"

export default class Feeds extends React.Component {
    constructor(props) {
        super(props);
        this.state = { items: [],
            status: undefined,
            loading: true
        };

        this.handleRequestUpdate = this.handleRequestUpdate.bind(this);
    }

    handleRequestUpdate () {
        this.getItems();
    }

    componentDidMount() {
        this.getItems();
    }

    getItems() {
        this.setState({ status: undefined, response: "", loading: true })                    

        fetchFeeds()
            .then((conf) =>  {
                this.setState({ loading: false })                    
                
                if (conf.error) { 
                    this.setState({ response: conf.error.toString()})                    

                    if (conf.error.response && conf.error.response.status) {
                        this.setState({ status: conf.error.response.status})                    
                        return null;  
                    }   
                }
                 
                if (conf && conf.status) {
                    this.setState({status:conf.status})
                }

                if (conf && conf.data) {
                    this.setState({items: conf.data })
                }                 
            })            
            .catch((conf) =>  {
                this.setState({response: "catch", loading: false })
            })

        }

    render() {
        return (
            <div>
                <Box>
                    <h1>Feeds</h1>

                    {(this.state.loading) ? (
                        <span>
                            Loading...
                        </span>
                    ) : ("") }

                    {(this.state.status === 401) ? (
                        <span>
                            <h3>Server says 401 - unauthorized</h3>
                            <p>please login here: <Link to="/login">Login</Link>
                            </p>
                        </span>
                    ) : ("") }
 
                    {(this.state.items) ? (    
                        this.state.items.map((item, i) => {
                            return <Feed key={item.id} name={item.name} href={item.href} lastFetch={item.lastFetch} id={item.id} lastErrorDate={item.errorDate} lastErrorMsg={item.errorMsg} requestUpdateCb={this.handleRequestUpdate}/>
                        })
                    ) : ( "" ) }

                    {(this.state.status === 200 && this.state.items.length === 0) ? (
                        <span>
                            Es gibt noch keine Feeds.
                        </span>
                    ) : (
                        ""
                    ) }


                    
                    {(this.state.response && this.state.status !== 401) ? (
                        <span>
                            {this.state.response}
                        </span>
                    ) : ("") }
                </Box>
                <Box>
                    <FeedForm requestUpdateCb={this.handleRequestUpdate}></FeedForm>
                </Box>
            </div>
        );
    }
}