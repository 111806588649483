import React from "react";
import FeedItems from "./routeFeedItems/FeedItems.jsx";
import Feeds from "./routeFeeds/Feeds.jsx";
import Header from "./base/header.jsx";
import Footer from "./base/footer.jsx";
import PageContent from "./base/PageContent.jsx";
import LoginPage from "./login/LoginPage.jsx";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


function App() {
  return (
    <Router>
      <div>
        <Header />
        <PageContent>
            <Routes>
              <Route path="/login" element={<LoginPage />}></Route>
              <Route path="/feeds/" element={<Feeds />}></Route>
              <Route path="/" element={<FeedItems />}></Route>              
            </Routes>
          </PageContent>

          <Footer/>
      </div>
    </Router>
  );
}

export default App;
