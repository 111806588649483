import React from 'react';
import styled from 'styled-components'

const BoxStyled = styled.div`
    font-size: 16px;
    padding: 20px;
    line-height: 22px;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);
    background: rgba(255,255,255,0.7);
    border: solid 1px rgba(0,0,0,0.2);
    
    width: 100vw;
    overflow: auto;

    margin: 0 auto 20px;
    @media(min-width: 800px) {
        border-radius: 5px;
        width: 100%;
        max-width: 800px;
    }
    @media (prefers-color-scheme: dark) {
        background: #333;
    }

    & > :first-child {
        margin-top: 0;
    }
`

const BoxInner = styled.div`
    width: auto;
`

class Box extends React.Component {
    render() {
        return (
            <BoxStyled>
                <BoxInner>
                    {this.props.children}
                </BoxInner>
            </BoxStyled>
      );
    }
}

export default Box