import React from 'react';
import styled from "styled-components"
import {requestAccessToken} from "./auth"
import Box from "../base/Box"
import Button from "../base/Button"


const Form = styled.form`
    max-width: 300px;

    &.-error {
        animation: 200ms ease-out 2 nono;
    }
    
    @keyframes nono {
        0% {
            transform: translateX(0px);
        }
        25% {
            transform: translateX(10px);
        }
        75% {
            transform: translateX(-10px);
        }
        100% {
            transform: translateX(0px);
        }
    }
`

const LabelWrapper = styled.label`
    display: block;
    padding: 5px 0;
    
    input {
        font-size: 16px;
        line-height: 22px;
        padding: 4px 10px;
        width: 100%;
        box-sizing: border-box;
    }
`

const Label = styled.span`
    display: block;
    padding-right: 5px;
    font-size: 14px;
`

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { name: "", password: "", error: ""};
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        
    }

    handleChangeName(event) {
        this.setState({ name: event.target.value, error: false });
    }

    handleChangePassword(event) {
        this.setState({ password: event.target.value, error: false });
    }

    async handleSubmit(event) {
        this.setState({loading: true})

        event.preventDefault();
        const {error, response} = await requestAccessToken({name:this.state.name, password: this.state.password})
        this.setState({loading: false})
        if (error) {
            this.setState({error: error});
        }

        if (response) {
            this.setState({error: false });
            this.props.loginCb();
        }        
    }

    render() {
        let errorClass = ""
        if (this.state.error) {
            errorClass = "-error"
        }

        let buttonText = "Login"
        if (this.state.loading) {
            buttonText = "spinner"
        }

        return (
            <Box>
                <h2>Login</h2>

                <Form onSubmit={this.handleSubmit} className={errorClass}>
                    <LabelWrapper>
                        <Label>Name:</Label>
                        <input required type="text" value={this.state.name} onChange={this.handleChangeName} />
                    </LabelWrapper>
                    <LabelWrapper>
                        <Label>Passwort:</Label>
                        <input required type="password" value={this.state.password} onChange={this.handleChangePassword} />
                    </LabelWrapper>
                    <Button marginTop="true">{buttonText}</Button>

                    {(this.state.error && this.state.error.status) ? (
                     <div>{this.state.error.status}</div>
                ) : (
                   ""
                )}                
                    {(this.state.error && this.state.error.data) ? (
                     <div>{this.state.error.data}</div>
                ) : (
                   ""
                )}                
                </Form>                
            </Box>
        )
    }
}

export default LoginForm