import React from 'react';
import styled from "styled-components"
import Button from "../base/Button"
import feedAddRequest from "./feedAddFormRequest.js"

const Form = styled.form`
    max-width: 300px;
`

const LabelWrapper = styled.label`
    display: block;
    padding: 5px 0;
    
    input {
        font-size: 16px;
        line-height: 22px;
        padding: 4px 10px;
        width: 100%;
        box-sizing: border-box;
    }
`

const Label = styled.span`
    display: block;
    padding-right: 5px;
    font-size: 14px;
`

class FeedAddForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { name: "", id: "", href: "", loading: false, response: "", status: "" };
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeId = this.handleChangeId.bind(this);
        this.handleChangeHref = this.handleChangeHref.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeName(event) {
        this.setState({ name: event.target.value });
    }
    handleChangeId(event) {
        this.setState({ id: event.target.value });
    }
    handleChangeHref(event) {
        this.setState({ href: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();
        // do something
        // window.location.reload()

        this.setState({ loading: true })                    
        feedAddRequest({id:this.state.id, name: this.state.name, href: this.state.href})
            .then((conf) =>  {
                this.setState({ loading: false }) 
                
                if (conf && conf.status) {
                    this.setState({status:conf.status, response: "", id: "", name: "", href: ""})
                    this.props.requestUpdateCb()
                    // reset the form
                    this.formRef.reset();

                }                      
                
                if (conf.error) { 
                    this.setState({ response: conf.error.toString()})                    

                    if (conf.error.response && conf.error.response.status) {
                        this.setState({ status: conf.error.response.status})                    
                        return null;  
                    }   
                }
                               
            })            
            .catch((conf) =>  {
                this.setState({response: "catch", loading: false })
            })
            
    }

    render() {
        let buttonText = "hinzufügen" 
        if (this.state.loading) {
            buttonText = "spinner"
        }

        let statusText = ""
        if (this.state.status) {
            statusText = this.state.status
        }

        if (this.state.response) {
            statusText += " - " + this.state.response
        }
        
        // .formRef contains a reference to the form to reset it later
    
        return (
            <Form onSubmit={this.handleSubmit} ref={(el) => this.formRef = el}>
                <LabelWrapper>
                    <Label>Feedname</Label>
                    <input required type="text" value={this.state.name} onChange={this.handleChangeName} />
                </LabelWrapper>
                <LabelWrapper>
                    <Label>Id</Label>
                    <input required type="text" value={this.state.id} onChange={this.handleChangeId} />
                </LabelWrapper>
                <LabelWrapper>
                    <Label>Url</Label>
                    <input required type="text" value={this.state.href} onChange={this.handleChangeHref} />
                </LabelWrapper>
                <Button>{buttonText}</Button>                
                <div>{statusText}</div>
            </Form>
      );
    }
}

export default FeedAddForm