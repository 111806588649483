import React from 'react';
import styled from "styled-components"


const ContentStyled = styled.div`
    font-size: 16px;  
    overflow-x: hidden;

    a {
        color: #0746bc;
        font-weight: bolder;
        text-decoration: none;
        @media (prefers-color-scheme: dark) {
            color: #699dfe;
        }

        &:hover {
            color: #011f56;
            @media (prefers-color-scheme: dark) {
                color: #9bc7ff;
            }
        }
    }

    img {
        @media (prefers-color-scheme: dark) {
            filter: brightness(70%);
        }   
    }

`

class PageContent extends React.Component {
    buttonClicked = false

    render(props) {
        return (
            <ContentStyled>
                {this.props.children}    
            </ContentStyled>
        )
    }
}

export default PageContent