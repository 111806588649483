import React from 'react';
import styled from 'styled-components'

const Row = styled.div`
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
`

class ButtonRow extends React.Component {

    render() {
        return (
            <Row>
                {this.props.children}
            </Row>
      );
    }
}

export default ButtonRow