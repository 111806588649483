import React from 'react';
import styled from "styled-components"
import post from "./FeedItemButtonPost"



const ButtonStyled = styled.button`
    max-width: 800px;
    margin: auto;  
    display: block;
    width: 100%;
    border: solid 1px rgba(0,0,0,0.2);
    background: none;
    box-shadow: inset 0 0 45px 0 rgba(0,0,0,0.05), 1px 1px 3px 1px rgba(0,0,0,0.05);
    transition: all linear 200ms;
    border-radius: 3px;
    cursor: pointer;

    padding: 12px 20px;
    margin-top: 10px;
    text-align: left;
    font-size: 16px; 
    
    @media (prefers-color-scheme: dark) {
        background: rgba(0,0,0,0.2);
        border: solid 1px rgba(163, 255, 247,0.6);
        color: rgba(163, 255, 247,0.6);
        box-shadow: none;
    } 

    &:hover,
    &:focus {
        box-shadow: inset 0 0 25px 0 rgba(0,0,0,0.2), 1px 1px 3px 2px rgba(0,0,0,0.2);
        @media (prefers-color-scheme: dark) {
            box-shadow: inset 0 0 10px 0 rgba(163, 255, 247,0.15);, 1px 1px 3px 2px rgba(0,0,0,0.4);
            background: rgba(0,0,0,0.4);

        }
    }
`

class FeedItemButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = { loading: false, status: false, isToggleOn: true, errorResponse: false };

        // This binding is necessary to make `this` work in the callback    
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() { 

        this.setState(state => ({ loading: true, errorResponse: false }))

        post(this.props.guid, this.state.isToggleOn) 
            .then((conf) =>  {
                this.setState(state => ({ loading: false }));
                
                if (conf.error) { 
                    this.setState({ errorResponse: conf.error.toString()})                    

                    if (conf.error.response && conf.error.response.status) {
                    this.setState({ status: conf.error.response.status, errorResponse: conf.error.response.status })                    
                    return null;  
                    } 
                }
                 
                if (conf && conf.status) {
                    this.setState({status:conf.status})
                }

                if (conf && conf.status && conf.status === 200) {
                    this.setState(state => ({ isToggleOn: !state.isToggleOn }));
                }                
            })            
            .catch((conf) =>  {
                console.warn("fetchFeedItems catch conf", conf)
            })
    }

    render() {

        let message;

        if (this.state.isToggleOn) {
            message = "als gelesen markieren"
        } else {
            message = "doch nicht"
        }

        if (this.state.loading) {
            message = "spinner"
        }

        if (this.state.errorResponse) {
            message = this.state.errorResponse
        }

        return (
            <ButtonStyled onClick={this.handleClick}>       
                {message}
            </ButtonStyled>
        );
    }
}



export default FeedItemButton