import axios from "axios";
import {getAuthCode} from "../login/auth"
import {getHost} from "../login/auth"

export default async function fetchRequest(id) {
  try {
    let response = await axios({
        url: getHost() + "/feedFetch",
        method: 'post',
        headers: {'Authorization': getAuthCode()},
        data: {
          id: id
        }
    })
    return response
  } catch (error) {
    return { error };
  }
}
