import React from 'react';
import styled from "styled-components"
import FeedEditForm from "./FeedEditForm"
import FeedShow from "./FeedShow"

const Wrapper = styled.div`
    max-width: 400px;

    & + & {
        border-top: solid 1px #bbb;
        padding-top: 10px;
        margin-top: 8px;
    }
`

class Feed extends React.Component {
    constructor(props) {
        super(props)
        this.state = {expanded: false }

        this.handleExpansion = this.handleExpansion.bind(this)
        this.updateSuccessCb = this.updateSuccessCb.bind(this)
    }

    handleExpansion (expanded) {
        this.setState({ expanded: expanded }, this.render)
    }

    updateSuccessCb () {
        this.setState({ expanded: false })
        this.props.requestUpdateCb()
    }

    render() {
        return (
            <Wrapper>
                <FeedShow 
                    href={this.props.href}
                    id={this.props.id} 
                    name={this.props.name} 
                    expansion={this.state.expanded}
                    expansionCb={this.handleExpansion} 
                    lastFetch={this.props.lastFetch} 
                    lastErrorDate={this.props.lastErrorDate} 
                    lastErrorMsg={this.props.lastErrorMsg} >
                </FeedShow>

                {(this.state.expanded) ? (    

                    <FeedEditForm 
                        id={this.props.id} 
                        href={this.props.href} 
                        name={this.props.name} 
                        cancelCb={this.handleCancel} 
                        updateSuccessCb={this.updateSuccessCb}
                        deleteSuccessCb={this.props.requestUpdateCb}>
                    </FeedEditForm>
                ) : ( "" ) }
            </Wrapper>
      );
    }
}

export default Feed