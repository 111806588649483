import axios from "axios";
import {getAuthCode} from "../login/auth"
import {getHost} from "../login/auth"

export default async function post(guid, markAsRead) {
  try {
    let response = await axios({
        url: getHost() + "/markAsRead",
        method: 'post',
        headers: {'Authorization': getAuthCode()},
        data: {
          guid: guid,
          markAsRead: markAsRead
        }
    })
    return response
  } catch (error) {
    return { error };
  }
}
