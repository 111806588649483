import React from 'react';
import styled from "styled-components"
import dompurify from 'dompurify';
import FeedItemButton from "./FeedItemButton"
import formatDate from "../base/formatDate"

const H2 = styled.h2`
    margin: 0;
    max-width: 800px;
    margin: auto;
    font-weight: normal;
    font-size: 23px;
    line-height: 26px;
    @media(min-width: 500px) {
        font-size: 26px;
        line-height: 32px;
    }
`

const FeedItemWrapper = styled.div`
    font-size: 16px;
    padding: 20px;
    line-height: 22px;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);
    background: rgba(255,255,255,0.7);
    border: solid 1px rgba(0,0,0,0.2);

    max-width: 800px;
    margin: 0 auto 20px;

    @media (prefers-color-scheme: dark) {
        background: #333;
    }
    
    @media(min-width: 800px) {
        border-radius: 5px;
    }
`
const Meta = styled.div`
   font-size: 14px;
   color: #777;
   max-width: 800px;
   margin: 5px auto 10px;
   line-height: 18px;
`

const Description = styled.div`
    max-width: 800px;
    margin: auto;    

    figure {
        width: 100% !important;
        padding: 0;
        overflow: auto;
        margin: 0;
    }

    img {
        // but why 1px?
        max-width: calc(100vw - 1px);
        height: auto !important;
        margin: 0 -20px;
    }

    @media(min-width: 820px) {
        img {
            max-width: 800px;
            margin: 0;
        }
    }

    iframe {
        width: calc(100vw - 3px);
        height: 56.25vw;
        margin: 0 -20px;
        border: solid 1px #000;
    }
    @media(min-width: 820px) {
        iframe {
            width: 820px;
            height: 421px;
            margin: 0;        
        }
    }

    .wp-caption-text,
    .figcaption {
        font-size: 14px;
        color: #444;
        margin-top: 0;

        max-width: calc(100vw - 40px);
        padding: 0 20px;
        @media(min-width: 820px) {
            max-width: 800px;
            padding: 0;
        }
    }

    .wp-caption {
        @media(max-width: 819px) {
            width: 100vw !important;
            margin: 0 -20px;
            
            img {
                margin: 0;
            }
        }
        @media(min-width: 820px) {
            width: 820px !important;
        }
    }



    .shariff-buttons {
        display: none;
    }

    a {
        margin: 0 !important; // for linked images from blogspot
    }

    pre {
        overflow: auto
    }


`

class FeedItem extends React.Component {
    render() {
        const sanitizer = dompurify.sanitize;
        let description = [this.props.description]
        let descriptionSanitized = sanitizer(description, { ADD_TAGS: ['iframe'] })
        
        let heroImage = "";
        if (this.props.image) {
            heroImage = <img src={this.props.image} alt="" />
        }

        let dateFormated = formatDate(this.props.date)

        return (
            <FeedItemWrapper>
                <H2><a href={this.props.link}>{this.props.title}</a></H2>
                <Meta>
                    <div>
                        {this.props.author} schrieb {dateFormated} auf <a href={this.props.bloglink}>{this.props.blogtitle}</a>
                    </div>
                </Meta>
                {heroImage}
                <Description dangerouslySetInnerHTML={{ __html: descriptionSanitized }} />
                
                <FeedItemButton guid={this.props.guid} />
        </FeedItemWrapper >
      );
    }
}

export default FeedItem