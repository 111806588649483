import React from 'react';
import Button from "../base/Button"
import fetchRequest from "./feedFetchButtonRequest"

class FeedFetchButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = { loading: false, status: false, isToggleOn: true, errorResponse: false };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState(state => ({ loading: true, errorResponse: false }))

        fetchRequest(this.props.id) 
            .then((conf) =>  {
                this.setState(state => ({ loading: false }));
                
                if (conf.error) { 
                    console.warn("FeedFetchButton fetchRequest error", conf)
                    this.setState({ errorResponse: conf.error.toString()})                    

                    if (conf.error.response && conf.error.response.status) {
                        this.props.fetchErrorCb({ status: conf.error.response.status, errorResponse: conf.error.response.status })
                    return null;  
                    } 
                }
                 
                if (conf && conf.status) {
                    this.setState({status:conf.status})
                }

                if (conf && conf.status && conf.status === 200) {
                    this.setState(state => ({ isToggleOn: !state.isToggleOn }));
                    this.props.fetchSuccessCb()
                }                
            })            
            .catch((conf) =>  {
                console.warn("FeedFetchButton catch conf", conf)
            })
    }

    render () {   
        let buttonText = "aktualisieren" 
        if (this.state.loading) {
            buttonText = "spinner"
        }

        return <Button type="button" layout="neutral" action={this.handleClick}>{buttonText}</Button>  
    }
}

export default FeedFetchButton