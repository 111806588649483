let axios = require("axios")

let accessToken = localStorage.getItem("accessToken")
let name = localStorage.getItem("name")


let host = "https://readerbe.skulptist.de"
if (window.location.hostname === "localhost") {
    host = "//" + window.location.hostname + ":3150";
}

export function getAuthCode () {
 return accessToken
}

export function getName () {
    return name
}

export function hasCredentials () {
    return (accessToken)
}

export function logOff () {
    accessToken = undefined
    localStorage.clear();
}

export function getHost () {
    return host
}

export async function requestAccessToken(conf) {
    if (conf && conf.name && conf.password) {
        try {
            let response = await axios({
                url: getHost() + "/requestAccessToken",
                method: 'post',
                data: {
                    name: conf.name,
                    password: conf.password
                  }
                })

            name = conf.name

            if (response && response.status === 200 ) {
                localStorage.setItem("name", conf.name)
                if (response.data && response.data.token) {
                    localStorage.setItem("accessToken", response.data.token)
                    accessToken = response.data.token
                }
                return {response: true}
            }
          } catch (error) {
             return {error: error.response}
          }
    }
}
