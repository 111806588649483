import React from 'react';
import {getName} from "./auth"

class Name extends React.Component {
    render() {

        let name = getName() || "Gast"
        return (
            <div>
                {name}
            </div>
        )
    }
}

export default Name