import React from 'react';
import FeedItem from "./FeedItem"
import fetchFeedItems from "./fetchFeedItems"
import { Link } from 'react-router-dom';
import Box from "../base/Box"

export default class FeedItems extends React.Component {
    state = {
        items: [],
        status: undefined,
        loading: true
    };

    componentDidMount() {
        this.getItems();
    }

    getItems() {
        this.setState({ status: undefined, response: "", loading: true })                    

        fetchFeedItems()
            .then((conf) =>  {
                this.setState({ loading: false })                    
                
                if (conf.error) { 
                    this.setState({ response: conf.error.toString()})                    

                    if (conf.error.response && conf.error.response.status) {
                        this.setState({ status: conf.error.response.status})                    
                        return null;  
                    }   
                }
                 
                if (conf && conf.status) {
                    this.setState({status:conf.status})
                }

                if (conf && conf.data) {
                    console.log("got feedItems", conf.data)
                    this.setState({items: conf.data })
                }                 
            })            
            .catch((conf) =>  {
                this.setState({response: "catch", loading: false })
            })

        }

    render() {
        return (
            <div>
                {(this.state.loading) ? (
                    <Box>
                        Loading...
                    </Box>
                ) : ("") }

                {(this.state.status === 401) ? (
                    <Box>
                        <h3>Server says 401 - unauthorized</h3>
                        <p>please login here: <Link to="/login">Login</Link>
                        </p>
                    </Box>
                ) : ("") }

                
                {(this.state.items) ? (
                    this.state.items.map((item, i) => {
                        return <FeedItem key={item.guid} bloglink={item.bloglink} blogtitle={item.blogtitle} title={item.title} description={item.description} date={item.date} link={item.link} author={item.author} guid={item.guid} timestamp={item.timestamp} />
                    })
                ) : ( "" ) }

                {(this.state.status === 200 && this.state.items.length === 0) ? (
                    <Box>
                        Es gibt nichts neues.
                    </Box>
                ) : (
                    ""
                ) }


                
                {(this.state.response && this.state.status !== 401) ? (
                    <Box>
                        {this.state.response}
                    </Box>
                ) : ("") }
            </div>
        );
    }
}