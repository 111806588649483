import React from 'react';
import {logOff} from "./auth"
import Name from "./Name"
import Box from "../base/Box"
import Button from "../base/Button"


class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.handleLogOffClick = this.handleLogOffClick.bind(this);
    }

    handleLogOffClick() {
        logOff()
        window.location.reload()
    }

    render() {
        return (
            <Box>
                <h2>Profile</h2>
                {(this.props.isLoggedIn) ? (
                    <div>
                        <h3>Angemeldet als <Name/></h3>
                        <Button marginTop="true" action={this.handleLogOffClick}>Logoff</Button>
                    </div>
                ) : (
                    <h3>Gast</h3>
                ) }
            </Box>
        )
    }
}

export default Profile