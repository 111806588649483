import React from 'react';
import styled from "styled-components"
import Button from "../base/Button"
import ButtonRow from "../base/ButtonRow"
import postFeedAdd from "./feedAddFormRequest"
import FeedDeleteButton from "./FeedDeleteButton"
import FeedFetchButton from "./FeedFetchButton"
const Form = styled.form`
`

const FlexGrow = styled.div`
    flex-grow: 1;
`

const LabelWrapper = styled.label`
    display: block;
    padding: 5px 0;
    
    input {
        font-size: 16px;
        line-height: 22px;
        padding: 4px 10px;
        width: 100%;
        box-sizing: border-box;
    }
`

const Label = styled.span`
    display: block;
    padding-right: 5px;
    font-size: 14px;
`

class FeedEditForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { name: props.name, href: props.href, loading: false, response: "", status: "", changed: false };
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeHref = this.handleChangeHref.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDeleteError = this.handleDeleteError.bind(this);
        this.handleFetchSuccessCb = this.handleFetchSuccessCb.bind(this);
        this.handleFetchError = this.handleFetchError.bind(this);
    }

    handleChangeName(event) {
        this.setState({ name: event.target.value, changed: true });
    }
    handleChangeHref(event) {
        this.setState({ href: event.target.value, changed: true });
    }

    handleDeleteError(event) {
        console.warn("handleDeleteError", event)
        this.setState({ status: event.status, response: event.errorResponse });
    }

    handleFetchError(event) {
        console.warn("handleFetchError", event)
        this.setState({ status: event.status, response: event.errorResponse });
    }

    handleFetchSuccessCb(event) {
        this.setState({ status: "200", response: "" })
        this.props.updateSuccessCb()
    }

    
    handleSubmit(event) {
        event.preventDefault();

        this.setState({ loading: true })                    
        postFeedAdd({id:this.props.id, name: this.state.name, href: this.state.href})
            .then((conf) =>  {
                this.setState({ loading: false }) 
                
                if (conf && conf.status) {
                    this.setState({status:conf.status, response: "", id: "", name: "", href: ""})
                    this.props.updateSuccessCb()
                }                      
                
                if (conf.error) { 
                    this.setState({ response: conf.error.toString()})                    

                    if (conf.error.response && conf.error.response.status) {
                        this.setState({ status: conf.error.response.status})                    
                        return null;  
                    }   
                }
                               
            })            
            .catch((conf) =>  {
                this.setState({response: "catch", loading: false })
            })
            
    }

    render() {
        let updateText = "ändern" 
        if (this.state.loading) {
            updateText = "spinner"
        }
     
        let statusText = ""
        if (this.state.status) {
            statusText = this.state.status
        }

        if (this.state.response) {
            statusText += " - " + this.state.response
        }
          
        return (
            <Form onSubmit={this.handleSubmit}>
               <LabelWrapper>
                    <Label>FeedId</Label>
                    {<input required disabled type="text" value={this.props.id} />}
                </LabelWrapper>
               <LabelWrapper>
                    <Label>Feedname</Label>
                    <input required type="text" value={this.state.name} onChange={this.handleChangeName} />
                </LabelWrapper>
                
                <LabelWrapper>
                    <Label>Url</Label>
                    <input required type="text" value={this.state.href} onChange={this.handleChangeHref} />
                </LabelWrapper>

                <ButtonRow>
                    <div>
                        <FeedFetchButton id={this.props.id} fetchErrorCb={this.handleFetchError} fetchSuccessCb={this.handleFetchSuccessCb}></FeedFetchButton>             
                    </div>
                    <div>
                        <FeedDeleteButton id={this.props.id} deleteErrorCb={this.handleDeleteError} deleteSuccessCb={this.props.deleteSuccessCb}></FeedDeleteButton>
                    </div>
                    {(this.state.changed) ? (    
                        <FlexGrow>
                            <Button id={this.props.id}>{updateText}</Button>   
                        </FlexGrow>
                    ) : ( "" ) }
                </ButtonRow>
                <div>{statusText}</div>
            </Form>
      );
    }
}

export default FeedEditForm