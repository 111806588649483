import axios from "axios";
import { getAuthCode } from "../login/auth";
import { getHost } from "../login/auth";

export default async function feedAddRequest(conf) {
  if (conf && conf.id && conf.name && conf.href) {
    try {
      let response = await axios({
        url: getHost() + "/feed",
        method: "put",
        headers: {
          Authorization: getAuthCode(),
        },
        data: {
          id: conf.id,
          name: conf.name,
          href: conf.href,
        }
      });

      return response;

    } catch (error) {
      return { error };
    }
  } else {
    console.warn("feedAddFormRequest conf is missing or invalid, but should contain id, name and href", conf)
  }
}
