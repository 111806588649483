import React from 'react';
import styled from "styled-components"
import formatDate from "../base/formatDate"
import Button from "../base/Button"

const Wrapper = styled.div`
    padding: 5px 0;
    display: flex;
    width: 100%;
    justify-content: center;

`

const TextCol = styled.div`
    flex-grow: 1;
`

const Meta = styled.div`
    color: #666;
    font-size: 14px;
`

const ErrorHint = styled.div`
    color: red;
`

const ButtonCol = styled.div`
`

class FeedShow extends React.Component {
    constructor(props) {
        super(props);
        
        this.handleToggleClick = this.handleToggleClick.bind(this);
    }

    handleToggleClick () {
        this.props.expansionCb(!this.props.expansion)
    }

    render() {

        const lastFetchDate = formatDate(this.props.lastFetch)
        const lastErrorDate = formatDate(this.props.lastErrorDate)

        let errorHint = <ErrorHint>
            <div>Fehler: {lastErrorDate}</div>
            <div>{this.props.lastErrorMsg}</div>
            </ErrorHint>

        if (!this.props.lastErrorDate) {
            errorHint = ""
        }
    
        return (
            <Wrapper>
                <TextCol>
                    <a href={this.props.href}>{this.props.name}</a>
                    <Meta>
                        <div>
                            zuletzt geladen: {lastFetchDate}
                        </div>
                        {errorHint}
                    </Meta>
                </TextCol>
                <ButtonCol>
                    <Button action={this.handleToggleClick} layout="neutral">toggle</Button>
                </ButtonCol>
            </Wrapper>
      );
    }
}

export default FeedShow